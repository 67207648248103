@import '../../styles/variables';

.admin {

    .block__row {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        /* align-items: stretch; */
        flex-wrap: wrap;
        // border: 20px solid rgb(246, 246, 186);
        margin: 20px 0px 20px 0px;
    }

    .block__column {
        border: 1rem solid rgb(241, 186, 241);
        // flex: 1 0 200px;
    }

    .admin__item {
        // flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        padding: 20px 10px;
        text-decoration: none;
        color: inherit;
        // border: 1rem solid rgb(39, 133, 16);
        // flex: 1 0 200px;
    }
}